/* eslint-disable */
import React from "react"
import Layout from '../../components/theme/Layout'
import LandingPage from '../../components/pages/LandingPage'
import { IntlProvider, addLocaleData } from 'react-intl'
import en from '../../i18n/en.json'
import fr from '../../i18n/fr.json'

import enData from 'react-intl/locale-data/en'
import frData from 'react-intl/locale-data/fr'

import { Provider as EntryProvider } from '../../contest/context/EntryContext';

const messages = { en, fr }
const locale = 'en'

addLocaleData([...enData, ...frData])

const flattenMessages = ((nestedMessages, prefix = '') => {
  if (nestedMessages === null) {
    return {}
  }
  return Object.keys(nestedMessages).reduce((_messages, key) => {
    const value = nestedMessages[key]
    const prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      Object.assign(_messages, { [prefixedKey]: value })
    } else {
      Object.assign(_messages, flattenMessages(value, prefixedKey))
    }

    return _messages
  }, {})
})

const GearUpLandingPage = ({data}) => {
    return (
      <Layout
          meta={{
            title: 'Gear Up for Spring | ROYALE®'
          }}
          urls={{
            'en': `/springcleaningoffers`,
            'fr': `/fr/offresdenettoyageduprintemps`
          }}
          locale="en"
          theme_color="#A6CE39">
          <EntryProvider>
              <LandingPage
                  locale="en" />
          </EntryProvider>
      </Layout>
    )
}


export default GearUpLandingPage
